.pp{
max-width: 90%;
}

.main{
display: flex;
flex-flow: column wrap;
margin-left: 10%;
}

.anim{
display: flex;
margin-top: 25%;
}
.anim img{
width:100% ;
}

.main img{
max-width: 100%;
}

.main p{
font-family: Poppins;
font-size: 20px;
color: #505050;
}

.yellowH{
color:  #F8DA00 !important; 
font-family: Poppins;
font-style: normal;
font-weight: bold;
font-size: 20px;
line-height: 30px;
padding: 0;
}

.subHeading{
color:  #000000 !important;
font-style: normal;
font-weight: bold;
font-size: 20px;
line-height: 30px;
padding: 0;
}

.midimg{
align-self: center;
}

.imageDesc{
  width: 100%;
}


.midimg p{
align-self: center;
font-size: 15px;
text-align: center;
color: black;
}

.midimg img{
align-self: center;
border-radius: 15px;
margin-top:5%;
margin-bottom:5%;
transition: transform .5s ease;

}


.button1 {
width: 300px;
height: 76px;
background: #F8DA00;
border-color: #F8DA00;
border-radius: 120px;
border-style: solid;
font-family: Helvetica;
font-style: normal;
font-weight: bold;
font-size: 20px;
line-height: 80%;
letter-spacing: -0.045em;
color: #ffffff;
cursor: pointer;
transition: all 0.5s;
margin-top: 10%;
}



@media (min-width:500px)
{
/* .main{
margin-left: 10%;
margin-right: 10%;
} */

.imageDesc{
  width: 50%;
  }

.anim{
margin-top: 5%;
}

.button1 {
width: 450px;
height: 76px;
font-size: 30px;
}

.button1 span {
cursor: pointer;
display: inline-block;
position: relative;
transition: 0.5s;
}

.button1 span:after {
content: '\00bb';
position: absolute;
opacity: 0;
top: 0;
right: -20px;
transition: 0.5s;
}

.button1:hover span {
padding-right: 25px;
}

.button1:hover span:after {
opacity: 1;
right: 0;
}

.midimg img:hover{
transform: scale(1.2);
}

}