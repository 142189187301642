
 
 body {
    background: #ffffff;
 }  
 
 .container {
    width: 100%;
    height: 50px;
    position: fixed;
    top: 40%;
    left: 50%;
    margin-left: -25%;
    margin-top: -25px;
 }
 
 /* p {
    float: left; 
    line-height: 50px;
    font-size: 200%;
    color: #888;
 } */
 
 .scroller {
    height: 50px;
    line-height: 50px;
    float: left;
    margin-left: 5px;
    overflow: hidden;
 }
 
 .scroller span {
    display: block; 
    font-size: 250%;
    color: #000;
 }
 
 .scroller .inner {
    -webkit-animation: scroll 10s 1 ease-out;
    -moz-animation: scroll 10s 1 ease-out;
    animation: scroll 10s 1 ease-out;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
 }
 
 
 .blue {
    -webkit-animation: color 0.25s 1 linear;
    -moz-animation: color 0.25s 1 linear;
    animation: color 0.25s 1 linear;
    
       -webkit-animation-delay: 8s;
    -moz-animation-delay: 8s;
    animation-delay: 8s;
    
       -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    
 }
 
 
 .blueb {
    -webkit-animation: color 0.25s 1 linear;
    -moz-animation: color 0.25s 1 linear;
    animation: color 0.25s 1 linear;
    
          -webkit-animation-delay: 8.25s;
    -moz-animation-delay: 8.25s;
    animation-delay: 8.25s;
    
       -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
 }
 
 
 @-webkit-keyframes color {
    0%  {color: #000;}
    100% {color: #2c90c6;}
 }
 
 @-moz-keyframes color {
    0%  {color: #000;}
    100% {color: #2c90c6;}
 }
 
 @keyframes color {
    0%  {color: #000;}
    100% {color: #ff732f;}
 }
 
 
 
 
 
 
 
 
 
 
 
 
 @-webkit-keyframes scroll {
    11%  {margin-top: 0px;}
    22%  {margin-top: 0px;}
    33%  {margin-top: -50px;}
    44%  {margin-top: -50px;}
    55%  {margin-top: -100px;}
    66%  {margin-top: -100px;}
    77%  {margin-top: -150px;}
    88%  {margin-top: -150px;}
    100% {margin-top: -150px;}
 }
 
 @-moz-keyframes scroll {
    11%  {margin-top: 0px;}
    22%  {margin-top: 0px;}
    33%  {margin-top: -50px;}
    44%  {margin-top: -50px;}
    55%  {margin-top: -100px;}
    66%  {margin-top: -100px;}
    77%  {margin-top: -150px;}
    88%  {margin-top: -150px;}
    100% {margin-top: -150px;}
 }
 
 @keyframes scroll {
    11%  {margin-top: 0px;}
    22%  {margin-top: 0px;}
    33%  {margin-top: -50px;}
    44%  {margin-top: -50px;}
    55%  {margin-top: -100px;}
    66%  {margin-top: -100px;}
    77%  {margin-top: -150px;}
    88%  {margin-top: -150px;}
    100% {margin-top: -150px;}
 }