.header{
    width: 100%;
    margin-top: 25%;
}

@media(min-width:500px)
{
    .header{
        margin-top: 5%;
    }
}