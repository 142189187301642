@media (min-width: 500px) {
  .main {
    display: flex;
    flex-flow: column wrap;
    margin-top: 5%;
    align-content: center;
    justify-content: center;
    margin-left: 10%;
    margin-right: 10%;
  }
  .chalk {
    width: 80%;
    display: flex;
    flex-flow: column wrap;
  }
  .main img {
    width: 100%;
  }

  .chalk p {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 30px;
    text-align: justify;

    color: #000000;
  }

  .chalk h1 {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    line-height: 29px;
    margin-top: 5%;
    color: #000000;
  }

  .pic2 {
    margin: 5% 0 5% 0;
    text-align: center;
  }
  .chalk img {
    width: 70%;
  }

  .button1 {
    width: 450px;
    height: 96px;
    background: #222328;
    border-color: #222328;
    border-radius: 120px;
    border-style: solid;
    font-family: Helvetica;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 80%;
    letter-spacing: -0.045em;
    color: #ffffff;
    cursor: pointer;
    transition: all 0.5s;
  }

  
  .button1 span {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.5s;
  }
  
  .button1 span:after {
    content: '\00bb';
    position: absolute;
    opacity: 0;
    top: 0;
    right: -20px;
    transition: 0.5s;
  }
  
  .button1:hover span {
    padding-right: 25px;
  }
  
  .button1:hover span:after {
    opacity: 1;
    right: 0;
  }

  .blackbox {
    width: 90%;
    height: 182px;
    background: #222328;
    margin: 3% 2% 5%;

    border-radius: 10px;
  }

  .blackbox p {
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 50px;
    padding: 1% 0 0 2%;
    color: #fffefe;
  }

  .lastButton:hover {
    cursor: pointer;
  }
}

@media (max-width: 500px) {
  .main {
    display: flex;
    flex-flow: column wrap;
    margin-top: 5%;
    align-content: center;
    justify-content: center;
  }
  .chalk {
    width: 90%;
    display: flex;
    flex-flow: column wrap;
  }

  .chalk p {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 30px;

    color: #000000;
  }

  .chalk h1 {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    line-height: 29px;
    margin-top: 5%;
    color: #000000;
  }

  .pic2 {
    text-align: center;
  }
  .chalk img {
    width: 100%;
    height: 100px;
    margin: 15% 0 15% 2%;
  }
  .header{
    margin-top: 25%;
  }
  .button1 {
    width: 100%;
    height: 96px;
    background: #222328;
    border-color: #222328;
    border-radius: 120px;
    border-style: solid;
    font-family: Helvetica;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 80%;
    letter-spacing: -0.045em;
    cursor: pointer;
    /* transition: all 0.5s; */
    color: #ffffff;
    margin: 10% 0 10% 0;
  }

  

  /* .button1 span {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.5s;
  }
  
  .button1 span:after {
    content: '\00bb';
    position: absolute;
    opacity: 0;
    top: 0;
    right: -20px;
    transition: 0.5s;
  }
  
  .button1:hover span {
    padding-right: 25px;
  }
  
  .button1:hover span:after {
    opacity: 1;
    right: 0;
  }
   */

  .blackbox {
    width: 95%;
    height: 200px;
    background: #222328;

    border-radius: 10px;
  }

  .blackbox p {
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    line-height: px;
    padding: 7% 0 7% 7%;
    color: #fffefe;
  }

  .lastButton:hover {
    cursor: pointer;
  }
}
