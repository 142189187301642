.CaseStudy{
    align-items: center;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-content: center;
    overflow: hidden;
    margin-top: 15%;
}
.box2{
    align-items: center;
    text-align: center;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-content: center;
    overflow: hidden;
}

.heading{
    font-family: Poppins;
    font-weight: bold;
    font-size: 22px;
    color: #FF732F;
    margin: 0rem; 
    }
    
    .heading2{
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    color: #505050;
    margin: 0rem;
    }

.descr
{
    width: 80%;
    font-family: Poppins;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    color: #505050;
    align-items: center;
    align-content: center;
}

.caseButton{
    width: 173px;
    height: 44px;
    color: #FF732F;
    background-color: white;
    border: 3px solid #FF732F;
    box-sizing: border-box;
    border-radius: 8px;
}

.caseButton:hover {
    background-color: #FF732F;
    color: white;
    cursor: pointer;
}



.img{
    width: 100%;
}

.img2{
    width: 100%;
}

/*.....*/

@media (min-width:500px)
{
.CaseStudy{
    width:100%; 
    height: 40%;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    /* margin-block-start: 10%; */
    margin-top: 2%;    
    margin-bottom: 10%;

}

.box2{
    width:40%; 
    text-align: unset;
    display: flex;
    align-self: center;
    flex-flow: column wrap ;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 5%;
}

.heading{

    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    color: #FF732F;
    
    }
    
    .heading2{
    
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 36px;
    color: #505050;
    
    }
    .descr
    {
        width: 100%;
        font-family: Poppins;
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        color: #505050;
        margin: 0%;
        margin-top: 5%;
        
    }
    


.caseButton{
    
    width: 200px;
    height: 50px;
    color: #FF732F;
    font-size: 18px;
    background-color: white;
    border: 3px solid #FF732F;
    box-sizing: border-box;
    border-radius: 8px;
    margin-top: 10%;
}
.caseButton:hover {
    background-color: #FF732F;
    color: white;
    cursor: pointer;
}


.img{
    width: 55%;
    cursor: pointer;
}
.img:hover{
    transform: scale(0.9);
}

.img2:hover{
    transform: scale(0.9);
}

.img2{
    width: 45%;
    margin-left: 5%;
    cursor: pointer;
}

} 
 
