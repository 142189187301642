.pp{
max-width: 90%;
}

.main{
display: flex;
flex-flow: column wrap;
margin-left: 10%;
}

.anim{
display: flex;
margin-top: 25%;
}
.anim img{
width:100% ;

}

.main img{
max-width: 100%;
margin-top: 5%;
margin-bottom: 5%;
}

.main p{
font-family: Poppins;
font-size: 20px;
color: #505050;
}

.yellowH{
color: #E45C24 !important; 
font-family: Poppins;
font-style: normal;
font-weight: bold;
font-size: 20px;
line-height: 30px;
padding: 0;
}

.subHeading{
color:  #000000 !important;
font-style: normal;
font-weight: bold;
font-size: 20px;
line-height: 30px;
padding: 0;
}

.midimg{
align-self: center;
}

.imageDesc{
width: 100%;
}


.midimg p{
align-self: center;
font-size: 15px;
text-align: center;
color: black;
}

.midimg img{
align-self: center;
border-radius: 15px;
margin-top:5%;
margin-bottom:5%;
transition: transform .5s ease;

}


.button1 {
width: 300px;
height: 76px;
background: #E45C24;
border-color: #E45C24;
border-radius: 120px;
border-style: solid;
font-family: Helvetica;
font-style: normal;
font-weight: bold;
font-size: 20px;
line-height: 80%;
letter-spacing: -0.045em;
color: #ffffff;
cursor: pointer;
transition: all 0.5s;
margin-top: 10%;
}

.box3{
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    margin-bottom: 5%;
    
}


.b1{
    width: 30%;
    align-self: center;
}

.b2{
    align-self: center;
    width: 60%;
}


.box4{
    display: flex;
    flex-flow: column wrap;
    justify-content: space-between;
    margin-top:5%;
    margin-bottom:10%;
    
    font-family: Poppins;
    font-size: 10px;
    color: #4F4F4F;
    width: 90%;
    align-self: center;
}

.box41{
    align-self: center;
 
}

.box41 img{
    height: 500px;
}


.box42{
    align-self: center;
    text-align: center;
    font-size: 12px;
}


@media (min-width:500px)
{

.imageDesc{
width: 50%;
}
.wireframe{
    height: 750px;
}

.anim{
margin-top: 5%;
}

.button1 {
width: 450px;
height: 76px;
font-size: 30px;
}

.button1 span {
cursor: pointer;
display: inline-block;
position: relative;
transition: 0.5s;
}

.button1 span:after {
content: '\00bb';
position: absolute;
opacity: 0;
top: 0;
right: -20px;
transition: 0.5s;
}

.button1:hover span {
padding-right: 25px;
}

.button1:hover span:after {
opacity: 1;
right: 0;
}

.midimg img:hover{
transform: scale(1.2);
}


.box4{
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    margin-top:5%;
    
    font-family: Poppins;
    font-size: 10px;
    color: #4F4F4F;
    width: 90%;
    align-self: center;
}

.box41{
    width: 30%;
    align-self: center;
}
.box41 img{
    height: 550px;
}

.box42{
    align-self: center;
    width: 60%;
}


}