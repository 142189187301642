.HomePage{
    display: flex;
    flex-flow: column wrap;
    margin-top: 20%;
    align-items: center;
    align-content: center;
    align-self: center;
    justify-content: center;
}

.HomePage p{
    align-content: center;
    margin: 0%;
    font-family: Poppins;
    color: #505050;
    font-style: normal;
    font-weight: 300;
    font-size: 22px;
    line-height: 36px;
    text-align: center;
}

.HomePage p2{
    align-content: center;
    margin-top: 10%;
    font-family: Poppins;
    color: #505050;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 36px;
    text-align: center;
}

.HomePage img{
    width: 80%;
    /* height: 60px; */
    align-content: center;
    margin: 3%;
}

.HomePage button{
    
    width: 173px;
    height: 44px;
    color: #FF732F;
    background-color: white;
    border: 3px solid #FF732F;
    box-sizing: border-box;
    border-radius: 8px;
    margin:10%;
}
.HomePage button:hover {
    background-color: #FF732F;
    color: white;
}

.header{
    margin-left: 10%;
    margin-right: 10%;
    
    margin-top:25%;   
    /* margin-bottom: 0px; */
   display: flex;
   flex-flow: column wrap;
   justify-content: center;
   align-content: center;
   align-self: center;
   }
   .header h1{
   font-family: Poppins;
   font-style: normal;
   font-weight: bold;
   font-size: 40px;
   color: #505050;
   margin: 0rem;
   
   }
   .header p{
   font-family: Poppins;
   font-style: normal;
   font-weight: normal;
   font-size: 25px;
   color: #505050;
   }
.other{
    margin-left: 10%;
    margin-top: 15%;
}

.other img{
    width: 90%;
    border-radius: 5px;
    margin-top: 2%;
}

.other p{
    font-family: Poppins;
font-weight: bold;
font-size: 22px;
color: #FF732F;
margin: 0rem; 
}

@media (min-width:500px)
{
    .main{
        margin-left: 10%;
         margin-right: 10%; 
        
    }

  
    .HomePage{
        display: flex;
        flex-flow: column wrap;
        margin-top: 10%;
        align-items: center;
        align-content: center;
        align-self: center;
        justify-content: center;
    }
    
    .HomePage p{
        align-content: center;
        margin: 0%;
        font-family: Poppins;
        color: #505050;
        font-style: normal;
        font-weight: 300;
        font-size: 22px;
        line-height: 36px;
        text-align: center;
    }
    
    .HomePage p2{
        align-content: center;
        margin-top: 10%;
        font-family: Poppins;
        color: #505050;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 36px;
        text-align: center;
    }
    
    .HomePage img{
        width: 70%;
        height: 70px;
        align-content: center;
        margin: 3%;
    }
    
    .HomePage button{
        
        width: 173px;
        height: 44px;
        color: #FF732F;
        background-color: white;
        border: 3px solid #FF732F;
        box-sizing: border-box;
        border-radius: 8px;
        margin:3%;
    }
    .HomePage button:hover {
        background-color: #FF732F;
        color: white;
        cursor: pointer;
    }
    .header{
        margin-top:10%;   
       display: flex;
       flex-flow: column wrap;
       justify-content: center;
       align-content: center;
       align-self: center;
       }
       .header h1{
       font-family: Poppins;
       font-style: normal;
       font-weight: bold;
       font-size: 40px;
       color: #505050;
       margin: 0rem;
       
       }
       .header p{
       font-family: Poppins;
       font-style: normal;
       font-weight: normal;
       font-size: 25px;
       color: #505050;
       }
       
       .other{
        margin-left: 5%;
        margin-top: 2%;
        transition: transform .1s;
    }

    .other img:hover{
    transform: scale(0.9);
    }

    .other img{
        width:40%;
        border-radius: 5px;
        margin-top: 2%;
    }

    .other p{
        font-family: Poppins;
    font-weight: bold;
    font-size: 30px;
    color: #FF732F;
    margin: 0rem; 
    }
   
}