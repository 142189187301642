.main{
    display: flex;
    flex-flow: column wrap;
    margin-top: 25%;
    align-content: center;
    justify-content: center;
}


.Dc{
    width: 90%;
    display: flex;
    flex-flow: column wrap;
    /* margin-top: 10%; */
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 30px;
    
    color: #505050;

}
.Dc img{
    width: 100%;
    margin-top: 10%;
    margin-bottom: 10%;
}

.purpleHeading{
    color: #5957D0;
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 30px;
    padding: 0;
}

.subHeading{
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 30px;
    color: #000000;
}

.header{
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 36px;
    
    text-align: center;
    
    color: #505050;
}

.pic3{
    width: 80%;
    align-items: center;
    align-self: center;
}

.day1{
    width: 90%;
    display: flex;
    flex-flow: column wrap;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 30px;
    padding-left: 5%;
}

.finalP{
    text-align: center;
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    line-height: 72px;
    color: #000000;
}

.combo{
    
    display: flex;
    justify-content:space-around;
}

.FourImages {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
  }

.aboutBox{
    display: flex;
    /* width:50%; */
    background: rgba(246, 228, 211, 0.8);
    border-radius: 8px;
    margin-right: 2%;
    margin-top: 2%;
}

.aboutBox h1{
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: #505050;
}

.aboutBox p{
    font-family: Poppins;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    color: #505050;
}



.aboutBox img{
    width:20%;
    align-self: center;
    margin-left: 5%;
}

.Aboutquad{
    width: 90%;
    align-self: center;
}

.Abouth1{
    font-weight: bold;
font-size: 45px;
line-height: 65px;
}

.Abouth2{
    font-family: Poppins;
font-style: normal;
font-weight: normal;
font-size: 45px;
line-height: 65px;
}
.caseButton{
    
    width: 173px;
    height: 44px;
    color: #FF732F;
    background-color: white;
    border: 3px solid #FF732F;
    box-sizing: border-box;
    border-radius: 8px;
   
}
.caseButton:hover {
    background-color: #FF732F;
    color: white;
}
.headerPic{
    width: 100%;
    margin-top: 25%;
}

  
@media (min-width: 500px) {
    .Dc{
        width: 80%;
    }
    .main{
        margin-top: 5%;
        margin-left: 5%;
        margin-right: 5%;
    }

    .Abouth1{
        font-weight: bold;
    font-size: 55px;
    line-height: 82px;
    }
    
    .Abouth2{
        font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 55px;
    line-height: 82px;
    }
    
   .headerPic{
       width: 100%;
       margin-top: 5%;
   }
   .aboutBox{
    display: flex;
    width:45%;
    background: rgba(246, 228, 211, 0.8);
    border-radius: 8px;
    margin-right: 2%;
    margin-top: 2%;
}

.aboutBox h1{
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: #505050;
}

.aboutBox p{
    font-family: Poppins;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    color: #505050;
}

}