@media(max-width:500px)
{
.main{
bottom: 0;
width: 100%;
background-color: #F6E4D3;
height: 140px;
}

.Footer{
display: flex;
flex-flow: column ;
justify-content: space-between;
}

.logo{
align-self: center;

}

.logo p{
font-style: normal;
font-weight: bold;
font-size: 25px;
line-height: 100%;
color: #FF732F;

}


}

@media (min-width:500px)
{
.main{
/* position: fixed; */
bottom: 0;
width: 100%;
height: 140px;
background-color: #F6E4D3;
}

.Footer{
display: flex;
flex-flow: column ;

}

.logo{
align-self: center;

margin-top: 1%;
}

.logo p{
font-style: normal;
font-weight: bold;
font-size: 25px;
line-height: 100%;
color: #FF732F;
margin-bottom: 5%;
transition: transform .2s;
font-family: Poppins;
}

.logo p:hover {
transform: scale(1.2);
}

.items{
align-self: center;
margin-right: 2%;
}

.items a{
    font-size: 20px;
}
.NavigationItem a{
    color: #505050;
    text-decoration: none;
    width:  100%;
    box-sizing:  border-box;
    margin: 2px 10px;
    }  

}




.NavigationItem{
margin: 10px 0;
box-sizing: border-box;
width: auto;
display: flex;
list-style: none;
justify-content: space-between;
}

.NavigationItem a{
color: #505050;
text-decoration: none;
width:  100%;
box-sizing:  border-box;
/* margin: 2px 10px; */
}  

.NavigationItem a:hover,
.NavigationItem a:active,
.NavigationItem a.active{
color: #FF732F;
}
