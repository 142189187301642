@font-face {
  font-family: Wendy LP Std;
  src: url("https://db.onlinewebfonts.com/t/6cb026fea85776ddde86e10b458738d4.eot");
  src: url("https://db.onlinewebfonts.com/t/6cb026fea85776ddde86e10b458738d4.eot?#iefix")
      format("embedded-opentype"),
    url("https://db.onlinewebfonts.com/t/6cb026fea85776ddde86e10b458738d4.woff2")
      format("woff2"),
    url("https://db.onlinewebfonts.com/t/6cb026fea85776ddde86e10b458738d4.woff")
      format("woff"),
    url("https://db.onlinewebfonts.com/t/6cb026fea85776ddde86e10b458738d4.ttf")
      format("truetype"),
    url("https://db.onlinewebfonts.com/t/6cb026fea85776ddde86e10b458738d4.svg#Wendy LP Std")
      format("svg");
}


.dribble {
    font-family: Wendy LP Std;
}

.NavigationItems {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-flow: column;
  height: 100%;
}

.NavigationItem {
  margin: 10px 0;
  box-sizing: border-box;
  width: auto;
  display: block;
  list-style: none;
}

.NavigationItem a {
  color: #505050;
  text-decoration: none;
  width: 100%;
  padding: 16px 10px;
  box-sizing: border-box;
  display: block;
}

.NavigationItem a:hover,
.NavigationItem a:active,
.NavigationItem a.active {
  color: #ff732f;
}


@media (min-width: 500px) {
  .NavigationItems {
    flex-flow: row;
  }
  .NavigationItem {
    margin: 0;
    height: 100%;
    display: flex;
    width: auto;
    align-items: flex-start;
    list-style: none;
  }

  .NavigationItem a {
    color: #505050;
    height: 100%;
    padding: 16px 10px;
    border-bottom: 4px solid transparent;
    font-size: 18px;
  }

  .img {
    width: 100%;
  }

  .NavigationItem a:hover,
  .NavigationItem a:active,
  .NavigationItem a.active {
    color: #ff732f;
  }
}
